import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from './core/modules/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { I18nModule } from './core/modules/i18n.module';
import { SharedModule } from './shared/shared.module';
import { FormControlModule, ImageControlModule, NavigationModule, StepsModule } from '@shared/components';
import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { HomeModule } from './pages/home/home.module';
import IdleTimer from './shared/class/idle-timer/idle-timer';
import { AppComponent } from './app.component';

@NgModule({
	declarations: [AppComponent],
	imports: [
		CoreModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		I18nModule.forRoot('app'),
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		SharedModule,
		NavigationModule,
		StepsModule,
		FormControlModule,
		ImageControlModule,
		HomeModule,
		HttpClientModule,
	],
	providers: [Title, IdleTimer],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {

}
