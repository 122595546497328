import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IAppConfig } from '../../models/core.model';
import { TagType } from './ContentType';
/**
 * Token service injectable
 */
@Injectable({ providedIn: 'root' })
export class GTMService extends GoogleTagManagerService {
	static readonly QUESTIONARY = 'Cuestionario';
	static readonly FORM = 'Formulario';
	static readonly SPLITTER = '|';
	static readonly TENANT = 'IMV';
	static readonly IDENTIFICATION = 'Identificación';

	pageNameSubject: BehaviorSubject<string> = new BehaviorSubject('');
	appConfig!: IAppConfig;
	pageNameSubjectSubscription!: Subscription;

	get isCaiss() {
		return this.appConfig.caiss === 1;
	}
	get isCitizen() {
		return this.appConfig.caiss === 0;
	}

	unsubscribe() {
		this.pageNameSubjectSubscription?.unsubscribe();
		this.pageNameSubject?.unsubscribe();
	}

	init(config: IAppConfig) {
		this.appConfig = config;
		if (this.isCitizen) {
			this.addGtmToDomSubscription();
			this.pageNameSubscription();
		}
	}

	private addGtmToDomSubscription() {
		this.addGtmToDom().then(
			res => console.log('GTM: is added GTM to DOM', res),
			err => console.log('GTM: is added GTM to DOM', err),
		);
	}

	private pageNameSubscription() {
		this.pageNameSubjectSubscription = this.pageNameSubject.subscribe(pageName => {
			if (pageName !== '') {
				const tag: TagType = { content: { page_name: pageName } };
				document.title = pageName;
				this.pushTag(tag).then(
					() => tag,
					() => tag,
				);
			}
		});
	}
}
