import { Component, OnInit, OnDestroy, Injector, ViewChild, AfterViewInit } from '@angular/core';
import { AuthService, CacheService, CoreValidators, GuardService } from '@app/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NavigationConfig } from '@shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonListsService, CommonModalsService, CommonQuestionnaireService, ICoexistencePlanResponse, CommonNavigationService } from '@shared/services';
import { init, resetForm } from '@shared/store';
import { cancelQuestionnaire } from '@app/questionnaire';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ImvUiModal } from '@app/ui-kit';
import { FeatureToggleService } from 'src/app/core/services/feature-toggle/feature-toggle.service';
import { PageComponent } from '../../../shared/components/page/page.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
type VasqueAndNavarraType = 'VASQUE' | 'NAVARRA' | undefined;
/**
 * Home component from home page
 */
@Component({
	selector: 'imv-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends PageComponent implements OnInit, AfterViewInit, OnDestroy {
	/**
	 * Content externalContent simulate incentive
	 */
	externalContent!: string;
	/**
	 * Determinate init button
	 */
	disableInitButton!: boolean;
	/**
	 * Determinate if province is delegated
	 */
	isDelegatedProvince: VasqueAndNavarraType;
	isDelegatedProvinceProceedings: VasqueAndNavarraType;
	isDelegatedProvinceVariations: VasqueAndNavarraType;
	private getAllQuestionsSubscription!: Subscription;
	private getPreIdentificationSubscription!: Subscription;
	private getBaremosSubscription!: Subscription;
	private getRefusalsSubscription!: Subscription;
	private getFormDataSubscription!: Subscription;
	private isPilotProvinceSubscription!: Subscription;
	private accessDraftSubscription!: Subscription;
	private accessProceedingsSubscription!: Subscription;
	employeeSimulationSafeUrl: SafeUrl;

	/**
	 * Contains delegated provinces of Vasque
	 */
	private get delegatedProvincesVasque(): string[] {
		return this.VASQUE_PROVINCE_DELEGATION ? ['01', '20', '48'] : [];
	}
	/**
	 * Contains delegated provinces of Navarra
	 */
	private get delegatedProvincesNavarra(): string[] {
		return this.NAVARRA_PROVINCE_DELEGATION ? ['31'] : [];
	}
	/**
	 * Feature Toggle of delegated provinces
	 */
	private VASQUE_PROVINCE_DELEGATION!: boolean;
	private NAVARRA_PROVINCE_DELEGATION!: boolean;
	private provinceSubscription!: Subscription;
	private provinceSubscriptionProceedings!: Subscription;
	private provinceSubscriptionVariations!: Subscription;

	/**
	 * Contains all data and disable  init button if is not CAISS
	 *
	 * @param _injector
	 * @param _store
	 * @param _router
	 * @param _route
	 * @param _commonQuestionnaire
	 * @param fb
	 * @param _commonLists
	 * @param _modalsService
	 * @param _guardService
	 * @param _authCaissService
	 * @param _commonNavigationService
	 * @param _cacheService
	 */
	constructor(
		_injector: Injector,
		private _store: Store,
		private _router: Router,
		private _route: ActivatedRoute,
		private _commonQuestionnaire: CommonQuestionnaireService,
		private fb: FormBuilder,
		private _commonLists: CommonListsService,
		private _modalsService: CommonModalsService,
		private _guardService: GuardService,
		private _authService: AuthService,
		private _commonNavigationService: CommonNavigationService,
		private _cacheService: CacheService,
		private featureToggleService: FeatureToggleService,
		private sanitizer: DomSanitizer,
	) {
		super(_injector);
		if (this.isCaiss && !this._authService.hasToken()) {
			this.disableInitButton = true;
		}
		this.employeeSimulationSafeUrl = sanitizer.bypassSecurityTrustUrl(`assets/SimuladorIncentivoEmpleo.html`);
	}
	/**
	 * Start request modal reference
	 */
	@ViewChild('modalStartRequest')
	modalStartRequest!: ImvUiModal;
	/**
	 * Access draft modal reference
	 */
	@ViewChild('modalAccessDraft')
	modalAccessDraft!: ImvUiModal;

	/**
	 * Start proceedings modal reference
	 */
	@ViewChild('modalStartProceedings')
	modalStartProceedings!: ImvUiModal;
	/**
	 * Access proceedings modal reference
	 */
	@ViewChild('modalAccessProceedings')
	modalAccessProceedings!: ImvUiModal;
	/**
	 * Start variations modal reference
	 */
	@ViewChild('modalStartVariations')
	modalStartVariations!: ImvUiModal;
	/**
	 * Navigation configuration
	 */
	config!: NavigationConfig;

	/**
	 * Link to imv definition
	 */
	moreInfoLink = 'https://www.seg-social.es/wps/portal/wss/internet/Trabajadores/PrestacionesPensionesTrabajadores/65850d68-8d06-4645-bde7-05374ee42ac7?changeLanguage=es%29';
	/**
	 * Link to variations comunications
	 */
	variationsInfoLink = 'https://pssc.seg-social.es/comunicacion-variaciones-imv';
	/**
	 * Link to variations comunications
	 */
	simulatorInfoLink = 'https://pssc.seg-social.es/comunicacion-variaciones-imv';
	/**
	 * Start request form
	 */
	formStartRequest!: FormGroup;
	/**
	 * Start proceedings form
	 */
	formStartProceedings!: FormGroup;
	/**
	 * Access request form
	 */
	formAccessDraft!: FormGroup;
	/**
	 * Access proceedings request form
	 */
	formAccessProceedings!: FormGroup;
	/**
	 * Start variations request form
	 */
	formStartVariations!: FormGroup;
	/**
	 * Indicates if start request is submitted
	 */
	submittedStartRequest = false;
	/**
	 * Indicates if start proceedings is submitted
	 */
	submittedStartProceedings = false;
	/**
	 * Indicates if start variations is submtited
	 */
	submittedStartVariations = false;
	/**
	 * Indicates if access draft  is submitted
	 */
	submittedAccessDraft = false;
	/**
	 * Indicates if access proceedings is submitted
	 */
	submittedAccessProceedings = false;
	/**
	 * Array that contains all provinces for form control
	 */
	options: { value: string; text: string; checked: boolean }[] = [];

	/**
	 * Called to initialize a component and views, clear cache, suscribes to questionnaire requests, set progressbar to 0,
	 * get provinces for start request modal and clear previous answers
	 */
	ngOnInit(): void {
		// Clear previous cache in case version has changed
		this._cacheService.clear();

		// Force to cache the questionnaire requests
		this.getAllQuestionsSubscription = this._commonQuestionnaire.getAllQuestions()
			.subscribe({ next: this.handleSuccessSubscription, error: this.handleErrorSubscription });
		if (this.isCitizen) {
			this.getPreIdentificationSubscription = this._commonQuestionnaire.getPreIdentification()
				.subscribe({ next: this.handleSuccessSubscription, error: this.handleErrorSubscription });
		}
		this.getBaremosSubscription = this._commonQuestionnaire.getBaremos()
			.subscribe({ next: this.handleSuccessSubscription, error: this.handleErrorSubscription });
		this.getRefusalsSubscription = this._commonQuestionnaire.getRefusals()
			.subscribe({ next: this.handleSuccessSubscription, error: this.handleErrorSubscription });
		this.getFormDataSubscription = this._commonQuestionnaire.getFormData()
			.subscribe({ next: this.handleSuccessSubscription, error: this.handleErrorSubscription });

		this._commonNavigationService.setProgressBar(0);
		// Get provinces for start request modal
		this._commonLists.getAllProvinces().subscribe(
			{
				next: provinces => {
					this.options = provinces.map((item: any) => ({ value: item.id, text: item.name, checked: false }));
					if (this.formStartRequest) {
						// provinces could be cached and form still doesn't exist
						this.formStartRequest.get('province')?.setValidators([Validators.required, CoreValidators.validateItem(this.options)]);
					}
					if (this.formStartProceedings) {
						// provinces could be cached and form still doesn't exist
						this.formStartProceedings.get('province')?.setValidators([Validators.required, CoreValidators.validateItem(this.options)]);
					}
				},
				error: this.handleErrorSubscription
			}
		);

		// Clear previous answers
		const isCaiss = this.isCaiss;
		this._store.dispatch(init({ isCaiss }));
		this._store.dispatch(cancelQuestionnaire());
		this._store.dispatch(resetForm());
		this._guardService.resetService();

		this._buildForms();

		this.featureToggleService.loadFeatureToggle('VASQUE_PROVINCE_DELEGATION', this);
		this.featureToggleService.loadFeatureToggle('NAVARRA_PROVINCE_DELEGATION', this);
	}

	/**
	 * Called after initialize component and views, suscribes to url changes and open modal start request if url contains province parm
	 */
	ngAfterViewInit() {
		this._route.queryParams.subscribe((params: any) => {
			if (params.province) {
				this.modalStartRequest.open();
			}
		});
	}

	/**
	 * Called when service is destroyed
	 */
	ngOnDestroy(): void {
		//Empty method
		this.provinceSubscription?.unsubscribe();
		this.provinceSubscriptionProceedings?.unsubscribe();
		this.provinceSubscriptionVariations?.unsubscribe();
		this.getAllQuestionsSubscription?.unsubscribe();
		this.getPreIdentificationSubscription?.unsubscribe();
		this.getBaremosSubscription?.unsubscribe();
		this.getRefusalsSubscription?.unsubscribe();
		this.getFormDataSubscription?.unsubscribe();
		this.isPilotProvinceSubscription?.unsubscribe();
		this.accessDraftSubscription?.unsubscribe();
		this.accessProceedingsSubscription?.unsubscribe();
	}


	/**
	 * Triggered when start request modal closes, updates start request form validaty
	 */
	onCloseStartRequestModal() {
		this.submittedStartRequest = false;
		this.formStartRequest.get('province')?.updateValueAndValidity();
	}

	/**
	 * Triggered when start proceedings modal closes, updates start request form validaty
	 */
	onCloseStartProceedings() {
		this.submittedStartProceedings = false;
		this.formStartProceedings.get('province')?.updateValueAndValidity();
	}

	/**
	 * Triggered when start variations modal closes, updates start request form validaty
	 */
	onCloseStartVariations() {
		this.submittedStartVariations = false;
		this.formStartVariations.get('province')?.updateValueAndValidity();
	}

	/**
	 * Triggered when start access draft modal closes, updates start request form validaty
	 */
	onCloseAccessDraftModal() {
		this.submittedAccessDraft = false;
		this.formAccessDraft.get('accessDraftToken')?.updateValueAndValidity();
	}

	/**
	 * Triggered when start  access proceedings modal closes, updates start request form validaty
	 */
	onCloseAccessProceedingsModal() {
		this.submittedAccessProceedings = false;
		this.formAccessProceedings.get('accessProceedingsToken')?.updateValueAndValidity();
	}

	/**
	 * Initialize forms
	 */
	private _buildForms() {
		const MAX_LENGHT = 8;
		this.formStartRequest = this.fb.group({
			province: new FormControl('', [Validators.required, CoreValidators.validateItem(this.options.length ? this.options : [])]),
		});

		this.formStartProceedings = this.fb.group({
			province: new FormControl('', [Validators.required, CoreValidators.validateItem(this.options.length ? this.options : [])]),
		});

		this.formStartVariations = this.fb.group({
			province: new FormControl('', [Validators.required, CoreValidators.validateItem(this.options.length ? this.options : [])]),
		});

		this.formAccessDraft = this.fb.group({
			accessDraftToken: new FormControl('', [
				Validators.required,
				Validators.minLength(MAX_LENGHT),
				Validators.maxLength(MAX_LENGHT),
				CoreValidators.notAllowingSpaces,
				CoreValidators.backendPattern(new RegExp(/[a-z,A-Z,0-9]{8}/g), 'is', 'Debe tener 8 caracteres'),
			]),
		});

		this.formAccessProceedings = this.fb.group({
			accessProceedingsToken: new FormControl('', [
				Validators.required,
				Validators.minLength(MAX_LENGHT),
				Validators.maxLength(MAX_LENGHT),
				CoreValidators.notAllowingSpaces,
				CoreValidators.backendPattern(new RegExp(/[a-z,A-Z,0-9]{8}/g), 'is', 'Debe tener 8 caracteres'),
			]),
		});
		this.provinceSubscriptionToChanges();
	}

	/**
	 * province subscription
	 */
	private provinceSubscriptionToChanges() {
		this.provinceSubscription = this.formStartRequest.controls['province'].valueChanges.subscribe(province => {
			this.isDelegatedProvince = this.checkDelegatedProvince(province);
		});
		this.provinceSubscriptionProceedings = this.formStartProceedings.controls['province'].valueChanges.subscribe(province => {
			this.isDelegatedProvinceProceedings = this.checkDelegatedProvince(province);
		});
		this.provinceSubscriptionVariations = this.formStartVariations.controls['province'].valueChanges.subscribe(province => {
			this.isDelegatedProvinceVariations = this.checkDelegatedProvince(province);
		});
	}

	/**
	 * Checks if the province is delegated
	 *
	 * @param province
	 * @returns
	 */
	private checkDelegatedProvince(province: any): VasqueAndNavarraType {
		if (this.delegatedProvincesVasque.find(prov => prov === province)) {
			return 'VASQUE';
		} else if (this.delegatedProvincesNavarra.find(prov => prov === province)) {
			return 'NAVARRA';
		} else {
			return undefined;
		}
	}

	/**
	 *  Allows Angular router navigate to request
	 */
	navigateRequest() {
		if (this.isCitizen) {
			this.modalAccessDraft.open();
		} else {
			const province = sessionStorage.getItem('provinceCaiss');
			if (province !== 'undefined') {
				this._router.navigateByUrl('/request');
			} else {
				this._modalsService.generalErrorModalEvent(
					// eslint-disable-next-line max-len
					'No puedes iniciar o continuar tus solicitudes, debido a que no hemos recibido la provincia en la que te encuentras. Inténtalo de nuevo más tarde. Si persiste el fallo, abre incidencia a través de PASS',
				);
			}
		}
	}

	navigateConsult() {
		this.modalStartProceedings.open();
	}

	navigateVariations() {
		this.modalStartVariations.open();
	}

	/**
	 *  Allows Angular router navigate to form step
	 */
	navigateQuestionnaire() {
		if (this.isCitizen) {
			this.modalStartRequest.open();
		} else {
			const province = sessionStorage.getItem('provinceCaiss');
			if (province !== 'undefined') {
				this._router.navigateByUrl('/form/step/1');
			} else {
				this.displayGeneralErrorModal();
			}
		}
	}

	private displayGeneralErrorModal() {
		this._modalsService.generalErrorModalEvent(
			// eslint-disable-next-line max-len
			'No puedes iniciar o continuar tus solicitudes, debido a que no hemos recibido la provincia en la que te encuentras. Inténtalo de nuevo más tarde. Si persiste el fallo, abre incidencia a través de PASS',
		);
	}

	/**
	 * Once the province is valid, it subscribes to _handleCoexistenceResponse()
	 */
	onSendProvince() {
		this.submittedStartRequest = true;
		if (this.formStartRequest.valid) {
			this.isPilotProvinceSubscription = this._commonLists.isPilotProvince(this.formStartRequest.get('province')?.value).subscribe({
				next: resp => {
					this._handleCoexistenceResponse(resp, true);
				},
				error: () => {
					this.modalStartRequest.close();
				},
			});
		}
	}

	/**
	 * Once the province is valid, it opens modalAccessProceedings
	 */
	onSendProvinceStartProceedings() {
		this.submittedStartProceedings = true;
		if (this.formStartProceedings.valid) {
			this.isPilotProvinceSubscription = this._commonLists.isPilotProvince(this.formStartProceedings.get('province')?.value).subscribe({
				next: () => {
					if (this.isCitizen) {
						this.modalAccessProceedings.open();
						this.modalStartProceedings.close();
					} else {
						this._router.navigateByUrl('/consult');
					}
				},
				error: () => {
					this.modalStartProceedings.close();
				},
			});
		}
	}

	/**
	 * Once the province is valid, it navigates to variations url
	 */
	onSendProvinceStartVariations() {
		this.submittedStartVariations = true;
		if (this.formStartVariations.valid) {
			this.isPilotProvinceSubscription = this._commonLists.isPilotProvince(this.formStartVariations.get('province')?.value).subscribe({
				next: () => {
					this.modalStartVariations.close();
					window.location.href = this.variationsInfoLink;
				},
				error: () => {
					this.modalStartVariations.close();
				},
			});
		}
	}

	/**
	 * Once accessDraftToken is valid, it subscribes to _handleCoexistenceResponse()
	 */
	onSendAccessDraftToken() {
		this.submittedAccessDraft = true;
		if (this.formAccessDraft.valid) {
			const token = this.formAccessDraft.get('accessDraftToken')?.value;
			this.accessDraftSubscription = this._commonLists.accessDraft(token).subscribe(resp => this._handleCoexistenceResponse(resp));
		}
	}

	/**
	 * Once accessProceedingsToken is valid, it subscribes to _handleCoexistenceResponse()
	 */
	onSendAccessProceedingsToken() {
		this.submittedAccessProceedings = true;
		if (this.formAccessProceedings.valid) {
			this.accessProceedingsSubscription = this._commonLists
				.accessProceedings(this.formAccessProceedings.get('accessProceedingsToken')?.value)
				.subscribe(resp => this._handleCoexistenceResponse(resp));
		}
	}

	/**
	 * Handle response from back to redirect to v1 or v2 website of IMV
	 *
	 * @param resp Response from backend
	 * @param newRequest If user is trying to start a new IMV request
	 */
	private _handleCoexistenceResponse(resp: ICoexistencePlanResponse, newRequest = false) {
		if (resp.imv) {
			if (newRequest) {
				this._guardService.allowAccessQuestionnaireHome();
			}
			this._router.navigateByUrl(resp.url);
		} else {
			window.open(resp.url, '_self', 'noopener');
		}
	}
	private handleSuccessSubscription = (res: any) => res;
	private handleErrorSubscription = (err: any) => err;
	onReferenceConsult = () => this._router.navigateByUrl('/consult?resend=1');
	onReferenceRequest = () => this._router.navigateByUrl('/request?resend=1');


}
