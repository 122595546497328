import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { map, Observable } from 'rxjs';

/**
 * Update Service
 */
@Injectable({
	providedIn: 'root',
})
export class UpdateService {
	constructor(private swUpdate: SwUpdate) { }
	private readonly versionEventType: 'VERSION_DETECTED' | 'VERSION_INSTALLATION_FAILED' | 'VERSION_READY' | 'NO_NEW_VERSION_DETECTED' = 'VERSION_DETECTED';
	checkUpdate = (): Observable<boolean> => this.swUpdate.versionUpdates.pipe(map((v: VersionEvent) => v.type === this.versionEventType));
}
