import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { APP_CONFIG, ConfigManager, IAppConfig } from '@app/core';
import { Observable } from 'rxjs';
import { RecoveryRequest, RecoveryRequestResponse, RecoveryToken, RecoveryTokenResponse, ExpedientResponse } from '@shared/models';

import { AbstractCommonConsultService } from './abstract-common-consult.service';

import { CommonConsultMapper } from './common-consult.mapper';
const commonConsultMapper = new CommonConsultMapper();

/**
 * Common Consult service
 */
@Injectable()
export class CommonConsultService implements AbstractCommonConsultService {
	/**
	 * HTTP client
	 */
	private readonly _httpClient: HttpClient;

	/**
	 * Claim host
	 */
	private readonly _hostSolicitud: string;

	/**
	 * Expedient host
	 */
	private readonly _hostExpediente: string;

	/**
	 * Tablas parametricas bff
	 */
	private readonly _bffTablasParametricas: string;

	/**
	 * Sets host URLs
	 *
	 * @param _injector injector
	 */
	constructor(private _injector: Injector) {
		const appConfig: IAppConfig = _injector.get<ConfigManager>(APP_CONFIG).config;
		this._httpClient = _injector.get(HttpClient);
		this._hostSolicitud = appConfig.apiHost.bff + 'solicitud/';
		this._hostExpediente = appConfig.apiHost.bff + 'expediente/';
		this._bffTablasParametricas = appConfig.apiHost.bff + 'tablas-parametricas';
	}

	/**
	 * Recovers claim draft token
	 *
	 * @param body recovery token
	 * @returns claim draft token
	 */
	recoverDraftToken(body: RecoveryToken): Observable<RecoveryTokenResponse> {
		const url = `${this._hostSolicitud}recuperarToken`;
		return this._httpClient.post<RecoveryTokenResponse>(url, body);
	}

	/**
	 * Recovers request token
	 *
	 * @param body recovery token
	 * @returns request token
	 */
	recoverRequestToken(body: RecoveryToken): Observable<RecoveryTokenResponse> {
		const url = `${this._hostExpediente}recuperarToken`;
		return this._httpClient.post<RecoveryTokenResponse>(url, body);
	}

	/**
	 * Recovers the consult
	 *
	 * @param body recovery request
	 * @returns consult
	 */
	recoverConsult(body: RecoveryRequest): Observable<RecoveryRequestResponse> {
		const url = `${this._hostSolicitud}recuperarSolicitud`;
		return this._httpClient.post<RecoveryRequestResponse>(url, body);
	}

	/**
	 * Recovers the request
	 *
	 * @param body recovery request
	 * @returns request
	 */
	recoverRequest(body: RecoveryRequest): Observable<RecoveryRequestResponse> {
		const url = `${this._hostSolicitud}`;
		return this._httpClient.post<RecoveryRequestResponse>(url, body);
	}

	/**
	 * Gets the citizen final status expedients
	 *
	 * @param body request body with document number
	 * @returns expedient list
	 */
	getCitizenExpedients(body: any): Observable<any> {
		const url = `${this._hostExpediente}recuperarExpedientesFasesFinales`;
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Recovers the expedient from citizen
	 *
	 * @param body recovery request
	 * @returns expedient
	 */
	recoverExpedient(body: RecoveryRequest): Observable<ExpedientResponse> {
		const url = `${this._hostExpediente}recuperarExpediente`;
		return this._httpClient.post<ExpedientResponse>(url, body);
	}

	/**
	 * Recovers the expedient from CAISS
	 *
	 * @param body recovery request
	 * @returns expedient
	 */
	recoverExpedientCaiss(body: any): Observable<ExpedientResponse> {
		const url = `${this._hostExpediente}recuperarExpedienteCaiss`;
		return this._httpClient.post<ExpedientResponse>(url, body);
	}

	/**
	 * Recovers the expedient by expedient id
	 *
	 * @param body expedient id
	 * @returns expedient
	 */
	recoverExpedientById(body: any): Observable<ExpedientResponse> {
		const url = `${this._hostExpediente}recuperarExpedienteIdExpediente`;
		return this._httpClient.post<ExpedientResponse>(url, body);
	}

	/**
	 * Recovers the expedient by expedient id
	 *
	 * @param numdocument DNI associated to the request
	 * @returns email associated
	 */
	recoverEmailByDocument(numdocument: string): Observable<any> {
		const url = `${this._hostExpediente}getEmailDocumento`;
		const body = {
			numDocumento: numdocument,
		};
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Gets the status code of the expedient
	 *
	 * @param idFuncional id funcional
	 * @returns status code
	 */
	statusCodeExpediente(idFuncional: string): Observable<any> {
		const url = `${this._hostExpediente}estado/` + idFuncional;
		return this._httpClient.get<any>(url);
	}

	/**
	 * Gets the status message of the expedient
	 *
	 * @param indSubEstado subestado index
	 * @returns status message
	 */
	statusMessageExpediente(indSubEstado: string): Observable<any> {
		const url = `${this._bffTablasParametricas}/subEstadoExpediente/` + indSubEstado;
		return this._httpClient.get<any>(url);
	}

	/**
	 * Uploads sanity documents
	 *
	 * @param body documents
	 * @returns Backend upload documents post call
	 */
	uploadSanityDocuments(body: any): Observable<any> {
		const url = `${this._hostExpediente}adjuntar/documentos`;
		body.documentos = commonConsultMapper.handleUnicodeDocuments(body.documentos);
		console.log(body);
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Sends a claim response which was not included in the original form version
	 *
	 * @param body request body
	 * @returns Backend send response post call
	 */
	sendClaimResponse(body: any) {
		const url = `${this._hostExpediente}addRespuestasExpediente`;
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Submits right option from citizen
	 *
	 * @param body @param body request body
	 * @returns Backend generate file post call
	 */
	submitRightOpt(body: any): Observable<any> {
		const url = `${this._hostExpediente}generarJustificanteDerechoOpcion`;
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Submits right option from CAISS
	 *
	 * @param body @param body request body
	 * @returns Backend generate file post call
	 */
	submitRightOptCaiss(body: any): Observable<any> {
		const url = `${this._hostExpediente}generarJustificanteDerechoOpcionCaiss`;
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Submits right option file
	 *
	 * @param body @param body request body
	 * @returns Backend uploads file post call
	 */
	submitRightOptFile(body: any): Observable<any> {
		const url = `${this._hostExpediente}adjuntarDerechoOpcion`;
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Gets the right option receipt
	 *
	 * @param body request body
	 * @returns right option receipt
	 */
	getRightOptReceipt(body: any): Observable<any> {
		const url = `${this._hostExpediente}descargarJustificanteDerechoOpcion`;
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Gets the IMV receipt
	 *
	 * @param idSolicitud claim id
	 * @returns IMV receipt
	 */
	getImvReceipt(idSolicitud: any): Observable<any> {
		const url = `${this._hostSolicitud}generarPDFSolicitud?idSolicitud=${idSolicitud}`;
		return this._httpClient.get<any>(url);
	}

	/**
	 * Gets the subsanation messages
	 *
	 * @param body request body
	 * @returns subsanation messages
	 */
	getSubsanationMessages(body: any): Observable<any> {
		const url = `${this._hostExpediente}notificacionesPorExpediente`;
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Gets the subsanation messages
	 *
	 * @param body request body
	 * @returns modifications receipt
	 */
	getModificationsReceipt(body: any): Observable<any> {
		const url = `${this._hostExpediente}descargarJustificanteCambioDatos`;
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Submits the identification diligence
	 *
	 * @param idFuncional id funcional
	 * @param option option
	 * @returns Backend respuesta identidad caiss put call
	 */
	submitIdentificationDiligence(idFuncional: any, option: string): Observable<any> {
		const url = `${this._hostExpediente}respuestaIdentidadCaiss/${idFuncional}`;
		return this._httpClient.put<any>(url, { respuestaIdentidadCaiss: option });
	}

	/**
	 * Submits the identification diligence file
	 *
	 * @param body request body
	 * @returns Backend upload document post call
	 */
	submitIdentificationDiligenceFile(body: any): Observable<any> {
		const url = `${this._hostExpediente}adjuntarDiligencia`;
		return this._httpClient.post<any>(url, body);
	}
}
