import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TokenService {
	private _caiss = false;
	private token!: string;
	getToken = (): string => this.token;
	setToken = (token: string) => this.token = token;
	enableCaiss = () => this._caiss = true
	isCaiss = () => this._caiss
}
