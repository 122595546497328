import { Component, HostListener, Injector } from '@angular/core';
import { APP_CONFIG, ConfigManager, GTMService, IAppConfig } from '@app/core';

@Component({
	selector: 'imv-page',
	templateUrl: './page.component.html',
	styleUrls: [],
})
export class PageComponent {
	get isCaiss() {
		return this.appConfig.caiss === 1;
	}
	get isCitizen() {
		return this.appConfig.caiss === 0;
	}

	isMobile = false;
	appConfig: IAppConfig;
	gtmService!: GTMService;

	constructor(_injector: Injector) {
		this.appConfig = _injector.get<ConfigManager>(APP_CONFIG).config;
		this.checkIfIsMobile(window.innerWidth);
		this.addGTM(_injector);
	}

	private addGTM(injector: Injector) {
		if (this.isCitizen) {
			this.gtmService = injector.get(GTMService);
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize = (event: any) => this.checkIfIsMobile(event.target.innerWidth);
	private checkIfIsMobile = (eve: number) => (this.isMobile = eve <= 670);

	trackByIndex(index: number, item: any) {
		const log = (i: any) => i;
		log(item);
		return index;
	}
}
