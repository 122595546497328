/* eslint-disable max-len */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
	selector: 'imv-dialog-saved',
	templateUrl: './dialog-saved.component.html',
	styleUrls: ['./dialog-saved.component.scss']
})
export class DialogSavedComponent extends DialogComponent implements OnInit, OnDestroy {
	override CUSTOM_ERRORS: string[] = [];

	override ngOnInit(): void {
		this.loadSubscriptions();
	}

	override loadSubscriptions = () => {
		this.handleEventSubscription = this.dialogsService.handleDialog.subscribe({
			next: (action: string | null) => {
				if (action) {
					this.is18nLoaded = true;
					this.action = action;
					this.open();
				}
			},
		});
	};

	override setLabels = () => {

		switch (this.action) {
			case 'REQUEST_CREATED':
				this.title = 'Solicitud guardada';
				this.description = 'El borrador se ha guardado correctamente. En caso de que quieras recuperar el borrador, debes introducir el código de referencia que recibiste por email la primera vez que guardaste. Recuerda, en caso de que no localices este código puedes recuperarlo fácilmente desde la página de acceso al borrador de la solicitud.';
				this.action = 'Aceptar';
				break;
			case 'EXPEDIENTE_CREATED':
				this.title = 'Expediente guardada';
				this.description = 'El expediente se ha guardado correctamente. En caso de que quieras recuperar el expediente, debes introducir el código de referencia que recibiste por email. Recuerda, en caso de que no localices este código puedes recuperarlo fácilmente desde la página de acceso a la solicitud.';
				this.action = 'Aceptar';
				break;
			default:
				this.title = 'Recurso creado';
				this.description = 'El recurso se ha guardado correctamente. ';
				this.action = 'Aceptar';
				break;
		}
	};

	override open = () => {
		this.setLabels();
		this.openDialogEmitter('SAVED');
		this.modal.open();
	};

	override close = () => {
		this.closeDialogEmitter('CLOSE');
		this.modal.close();
	};

}
