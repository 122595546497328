<section class="imv-navigation">
	<div class="imv-wrapper imv-wrapper--flex imv-wrapper--spacing">
		<imv-ui-progressbar *ngIf="isMobile" isMobile="true" [value]="numberPercent"></imv-ui-progressbar>
		<ng-container *ngIf="config$ | async as config">
			<div class="imv-navigation__left">
				<imv-ui-button eid="navigation-cancel-button" *ngIf="config.isCancel" [theme]="'secondary'" [buttonTitle]="'Cancelar solicitud'" (click)="onCancel()">
					<span slot="text">{{ 'Cancelar' }}</span>
				</imv-ui-button>
			</div>

			<div *ngIf="!isMobile" class="imv-navigation__progress">
				<imv-ui-progressbar [value]="numberPercent"></imv-ui-progressbar>
				<div class="imv-navigation__progress-text">
					<p class="imv-navigation__progress-text--estimation">{{ 'shared.estimatedTime' | translate }} : {{ minutes }} {{ 'shared.minutes' | translate }}</p>
					<span class="imv-navigation__percent">{{ numberPercent }}%</span>
				</div>
			</div>

			<div class="imv-navigation__right">
				<imv-ui-button
					eid="navigation-previous-button"
					mobile="mdc-button--resp"
					*ngIf="config.isPrevious"
					[theme]="'back'"
					variant="arrow-back"
					[buttonTitle]="'Ir al paso previo'"
					(click)="onPrevious()"
				>
					<span slot="text">{{ 'Anterior' }}</span>
					<img slot="icon-left" src="./assets/pwa/atoms-icons-24-ico-chevron-left.svg" aria-hidden="true" alt="Ir al paso anterior" />
				</imv-ui-button>

				<imv-ui-button
					eid="navigation-next-button"
					*ngIf="config.isNext"
					[disabled]="config.isDisabledNext"
					[theme]="'primary'"
					[buttonTitle]="'Ir al siguiente paso'"
					class="imv-navigation__button"
					[ngClass]="{ 'button--loading': config.isLoadingNext }"
					(click)="onNext()"
				>
					<span slot="text">{{ 'Siguiente' }}</span>
				</imv-ui-button>

				<imv-ui-button
					eid="navigation-start-button"
					*ngIf="config.isBegin"
					[disabled]="config.isDisabledBegin"
					[theme]="'primary'"
					(click)="onInit()"
					[buttonTitle]="'Comenzar solicitud'"
				>
					<span slot="text">{{ 'Comenzar' }}</span>
				</imv-ui-button>

				<imv-ui-button
					eid="navigation-send-button"
					*ngIf="config.isSubmit"
					[disabled]="config.isDisabledNext"
					class="imv-navigation__button"
					[ngClass]="{ 'button--loading': config.isLoadingNext }"
					[buttonTitle]="'Enviar solicitud'"
					[theme]="'primary'"
					(click)="onSubmit()"
				>
					<span slot="text">{{ 'Enviar' }}</span>
				</imv-ui-button>
			</div>
		</ng-container>
	</div>
</section>
