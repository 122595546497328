/* eslint-disable no-useless-escape */
import { CoreLogger } from './core-logger';

/**
 * Class for evaluating expressions defined in APPIAN.
 */
export class CoreExpressions {
	/**
	 * Evaluates a condition using the user responses and variables.
	 *
	 * @param condition Expression to be evaluated.
	 * @param responses User responses.
	 * @param variables Things like if the user is CAISS or the country is SPAIN.
	 * @returns Anythings a JavaScript expression may return, usually a boolean but can also be a number.
	 */
	static evaluate(condition: string, res: any, variables: any = {}) {
		const expression = convertToJs(condition);
		const responses = CoreExpressions.fixConditionResponses(condition, res);
		
		try {
			return new Function('r', 'CAISS', 'CP', 'MP', 'DC', 'ESPANYA', `return ${expression}`)(
				responses,
				variables.CAISS,
				variables.CP,
				variables.MP,
				variables.DC,
				variables.ESPANYA,
			);
		} catch (e: any) {
			CoreLogger.warning('Error evaluating %s', expression, e.message);
		}
		return false;
	}

	/**
	 * Evaluate if condition is from address context
	 * and cast the value to Number and
	 * return all response with PF128 and PF129 key casted
	 * 
	 * (address context = PF128+PF129)
	 * @param condition String
	 * @param responses need to create an interface for this param
	 * @returns 
	 */
	static fixConditionResponses(condition: string, responses: any) {
		if (condition === 'PF128+PF129') {
			responses['PF128'] = responses['PF128'] ? Number(responses['PF128']) : 0;
			responses['PF129'] = responses['PF129'] ? Number(responses['PF129']) : 0;
		}
		if(condition ==='PF81>0||PF84>0'){
			responses['PF81'] = responses['PF81'] ? parseFloat(responses['PF81']) : responses['PF81'];
			responses['PF84'] = responses['PF84'] ? parseFloat(responses['PF84']) : responses['PF84'];
		}
		return responses;
	}
}

/**
 * Converts an expression from APPIAN to a JavaScript like expression.
 * The resulting expression does double equal comparisons, not comparing types.
 *
 * @param visibility The expression to be converted to JavaScript expression.
 * @param pretty It has never been necessary to set this variable to true for the resulting expression to be correct.
 * @returns A correct JavaScript expression to be evaluated.
 */
const convertToJs = (visibility: string, pretty = false) => {
	let expressionJs = visibility;

	const regex = /(C?[MP][CIF][0-9_]+)/g;
	expressionJs = expressionJs.replace(regex, `r['$1']`);

	expressionJs = expressionJs.replace(/([^=\s><!])[\s]*=[\s]*([^=\s])/g, '$1 == $2');
	if (pretty) {
		expressionJs = expressionJs.replace(/([^\s|\&])[\s]*\&\&[\s]*([^\s|\&])/g, '$1 && $2');
		expressionJs = expressionJs.replace(/([^\s|\|])[\s]*\|\|[\s]*([^\s|\|])/g, '$1 || $2');
		expressionJs = expressionJs.replace(/([^>|\s])[\s]*>[\s]*([^>|=|\s])/g, '$1 > $2');
		expressionJs = expressionJs.replace(/([^>|\s])[\s]*<[\s]*([^>|=|\s])/g, '$1 < $2');
		expressionJs = expressionJs.replace(/([^\s])[\s]*>=[\s]*([^\s])/g, '$1 >= $2');
		expressionJs = expressionJs.replace(/([^\s])[\s]*<=[\s]*([^\s])/g, '$1 <= $2');
		expressionJs = expressionJs.replace(/([^\s])[\s]*!=[\s]*([^\s])/g, '$1 != $2');
		expressionJs = expressionJs.replace(/([^\s])[\s]*\+[\s]*([^\s])/g, '$1 + $2');
		expressionJs = expressionJs.replace(/([^\s])[\s]*\-[\s]*([^\s])/g, '$1 - $2');
	}
	
	return expressionJs;
};
