import { createAction, props } from '@ngrx/store';
import { NavigationConfig } from '../models/shared.model';

import { ESharedActions, ENavigationActions } from './shared.state';

/**
 * Public. Init action
 */
export const init = createAction(ESharedActions.INIT, props<{ isCaiss: boolean }>());

/**
 * Public. Navigation configuration
 */
export const navigationConfig = createAction(ENavigationActions.CONFIG, props<{ payload: NavigationConfig }>());

/**
 * Public. Completes the step
 */
export const completeStep = createAction(ESharedActions.COMPLETE_STEP, props<{ payload: number }>());

export const activeStep = createAction(ESharedActions.CURRENT_STEP, props<{ step: number }>())

/**
 * Public. Saves the request id
 */
export const saveRequestId = createAction(ESharedActions.SAVE_REQUEST_ID, props<{ id: string }>());

/**
 * Public. Saves the form responses
 */
export const saveFormResponses = createAction(ESharedActions.SAVE_FORM_RESPONSES, props<{ responses: any }>());

/**
 * Public. Saves the expedient
 */
export const saveExpedient = createAction(ESharedActions.SAVE_EXPEDIENT, props<{ responses: any }>());

/**
 * Public. Resets the form
 */
export const resetForm = createAction(ESharedActions.RESET_FORM);

// Private

export const signatureAction = createAction(ESharedActions.LOAD_SIGNATURE_MODULE, props<{ step: any }>());
