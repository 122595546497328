import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { TokenService } from './token.service';

/**
 * Header key for token
 */
const TOKEN_HEADER_KEY = 'Authorization';

/**
 * Auth Interceptor injectable. Implements HTTP interceptor
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	/**
	 * Auth interceptor constructor
	 *
	 * @param tokenService token service
	 */
	constructor(private tokenService: TokenService) { }

	/**
	 * Gets token, checks if the token is secure and handle the auth request
	 *
	 * @param req auth request
	 * @param next HTTP handler
	 * @returns handled request
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): any {
		/**
		 * Request token
		 */
		const token = this.tokenService.getToken();

		if (token && this.isSecureUrl(req.url)) {
			const clonedReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
			return next.handle(clonedReq);
		}

		return next.handle(req);
	}

	/**
	 * Checks if the request URL is secure
	 *
	 * @param requestUrl request URL
	 * @returns True if the URL is secure
	 */
	private isSecureUrl(requestUrl: string) {
		if (this.tokenService.isCaiss()) {
			return true;
		}

		const privateUrls: string[] = ['/solicitud', '/validacion-email', '/expediente', '/documento'];
		const publicUrls: string[] = ['/validarEmail'];

		const isPrivate = privateUrls.some(url => requestUrl.indexOf(url) !== -1);
		const isPublic = publicUrls.length > 0 ? publicUrls.some(url => requestUrl.indexOf(url) !== -1) : false;

		return isPrivate && !isPublic;
	}
}
