/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { DialogsService } from './dialogs.service';

/**
 * Error interceptor injectable. Implements HTTP interceptor
 */
@Injectable()
export class DialogsInterceptor implements HttpInterceptor {
	/**
	 * Error interceptor constructor
	 *
	 * @param _injector injector
	 */
	constructor(private _injector: Injector) { }

	/**
	 * Handles the request
	 *
	 * @param req HTTP request
	 * @param next HTTP handle
	 * @returns next HTTP request handled pipe
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): any {
		return next.handle(req)
			.pipe(tap((res: any) => this.checkOperations(res)))
			.pipe(catchError((err: HttpErrorResponse): any => this.checkDialogsContent(err)));
	}
	private checkOperations = (res: HttpResponse<any>) => res

	private checkDialogsContent = (err: HttpErrorResponse): HttpErrorResponse => {
		if (err.status === 422) {
			this._injector.get(DialogsService).handleError.next(JSON.stringify(err.error));
		} else {
			err.error.errorCode = 'UNHANDLED_ERROR';
			err.error.message = 'Sistema no disponible, intentelo más tarde';
			this._injector.get(DialogsService).handleError.next(JSON.stringify(err.error));
		}
		return err;
	};
}
