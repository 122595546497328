/* eslint-disable max-len */
import { GTMService } from './google-tag-manager.service';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IAppConfig } from '../../models/core.model';
import { FeatureToggleService } from '../../services/feature-toggle/feature-toggle.service';
export class Analytical {
	router: Router;
	gtmService: GTMService;
	language = 'es-ES';
	readonly SECTION = {
		HOME: 'Home',
		QUESTIONNAIRE: 'Questionnaire',
		IDENTIFICATION: 'Identification',
		FORM: 'Form',
		SUMMARY: 'Summary',
		CONSULT: 'Consult',
		DRAFT: 'Draft',
	};
	readonly ADD_MORE_DATA = false;
	readonly UNTITLED = 'UNTITLED';
	pageNameEvents: BehaviorSubject<null>;
	constructor(
		gtmService: GTMService,
		router: Router,
		private featureToggleService: FeatureToggleService,
		private activatedRoute: ActivatedRoute,
	) {
		this.router = router;
		this.gtmService = gtmService;
		this.pageNameEvents = new BehaviorSubject(null);
	}

	init(config: IAppConfig) {
		this.gtmService.init(config);
		this.getRouteEvents(this.router, this.gtmService, this.activatedRoute);
	}

	private getRouteEvents = (router: Router, gtmService: GTMService, activatedRoute: ActivatedRoute) => {
		router.events.subscribe((ev: Event) => {
			const isExpectedInstance = ev instanceof NavigationEnd;
			if (isExpectedInstance) {
				const firstChild = activatedRoute.snapshot.firstChild;
				let pageName = firstChild?.data['title'] || this.UNTITLED;
				pageName = firstChild?.children && firstChild?.children.length > 0 ? firstChild?.children[0].data['title'] : pageName;
				if (!(this.isInStep(ev) || this.isInForm(ev))) {
					gtmService.pageNameSubject.next(pageName);
				}
			}
		});
	};
	private isInStep = (ev: NavigationEnd) => ev.url.includes('/questionnaire/step/');
	private isInForm = (ev: NavigationEnd) => ev.url.includes('/form/step/');
}
