import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, EventEmitter, Output, OnDestroy, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { selectNavigation, init } from '@shared/store';
import { NavigationConfig } from '@shared/models';
import { CommonNavigationService } from '@shared/services';
import { tap } from 'rxjs/operators';
import { PageComponent } from '../page/page.component';

/**
 * IMV navigation component
 */
@Component({
	selector: 'imv-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent extends PageComponent implements OnInit, OnDestroy {
	/**
	 * Navigation configuration
	 */
	config!: NavigationConfig;

	/**
	 * Navigation configuration observable
	 */
	config$: Observable<NavigationConfig> = this._store.select(<any>selectNavigation).pipe(tap((config: any) => (this.config = config)));

	/**
	 * Progress bar number percent
	 */
	numberPercent: any = 0;

	/**
	 * Initial minutes for the progress bar
	 */
	readonly initialMinutes: number = 16;

	/**
	 * Minutes
	 */
	minutes = 0;

	/**
	 * Open modal
	 */
	@Output() openModal: EventEmitter<null> = new EventEmitter();
	private navigationServiceProgressbarSubscription!: Subscription;

	/**
	 * Navigation component constructor
	 *
	 * @param _store store
	 * @param _navigationService common navigation service
	 * @param _changeDetectionRef change detector ref
	 * @param router router
	 * @param translate translate service
	 */
	constructor(
		_injector: Injector,
		private _store: Store,
		private _navigationService: CommonNavigationService,
		private _changeDetectionRef: ChangeDetectorRef,
	) {
		super(_injector)
	}

	ngOnDestroy(): void {
		this.navigationServiceProgressbarSubscription?.unsubscribe();
	}

	/**
	 * Triggered when component is initialize, set time and progressbar
	 */
	ngOnInit(): void {
		this.minutes = this.initialMinutes;
		this.navigationServiceProgressbarSubscription = this._navigationService.progressBar().subscribe({ next: value => this.updateProgressBar(value) });
	}

	/**
	 * Triggered when components is initialize, clears timer, set timer estimets and sets progressbar
	 */
	onInit() {
		if (this.config.isDisabledBegin === false) {
			this._navigationService.setProgressBar(0);
			this._store.dispatch(init({ isCaiss: this.isCaiss }));
		}
	}

	/**
	 * If next button is enable, navigates with submit button
	 */
	onNext() {
		if (this.config.isDisabledNext === false) {
			this._navigationService.nextButton();
		}
	}

	/**
	 * Updates progress bar
	 *
	 * @param value value number
	 */
	updateProgressBar(value: number) {
		this.numberPercent = Number(value);
		this.minutes = Math.round(this.initialMinutes - (this.numberPercent * this.initialMinutes) / 100);
		this._changeDetectionRef.detectChanges();
	}

	/**
	 * Subscribe to next step
	 */
	onPrevious() {
		this._navigationService.previousButton();
	}

	/**
	 * Open modal on cancel
	 */
	onCancel() {
		this.openModal.emit();
	}

	/**
	 * Calls to the navigation service if the next button is enable
	 */
	onSubmit() {
		if (this.config.isDisabledNext === false) {
			this._navigationService.submitButton();
		}
	}
}
