import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaremosDto, FormDto, RefusalDto, DocumentDto } from '@shared/dtos';
import { ValidateEmailResponse, ValidateEmail, StepResponses, VerifyCaptcha, VerifyCaptchaResponse, FormConfig, EmailCaiss } from '@shared/models';

import { CommonQuestionnaireApiModule } from './common-questionnaire-api.module';
import { CommonQuestionnaireService } from './common-questionnaire.service';

/**
 * Common Questionnaire abstract service
 */
@Injectable({
	providedIn: CommonQuestionnaireApiModule,
	useClass: CommonQuestionnaireService,
	deps: [Injector],
})
export abstract class AbstractCommonQuestionnaireService {
	abstract sendRecoverDraftEmail(idSolicitud: string, email: string): Observable<any>;
	/**
	 * Gets the JSON form with the questions
	 */
	abstract getAllQuestions(): Observable<FormDto>;

	/**
	 * Gets the JSON form with the preidentification data
	 */
	abstract getPreIdentification(): Observable<FormDto>;

	/**
	 * Gets the JSON form with the modification data
	 */
	abstract getModifications(): Observable<FormDto>;

	/**
	 * Gets the baremos JSON
	 */
	abstract getBaremos(): Observable<BaremosDto>;

	/**
	 * Gets the refusals JSON
	 *
	 * @param idform form id
	 */
	abstract getRefusals(idform?: string): Observable<RefusalDto[]>;

	/**
	 * Checks if email is verified to receive a token
	 *
	 * @param responses validate email responses
	 */
	abstract recieveTokenValidation(responses: ValidateEmail): Observable<ValidateEmailResponse>;

	/**
	 * Checks if email to consult email
	 *
	 * @param responses validate email responses
	 */
	abstract checkIsEmailValidated(responses: ValidateEmail): Observable<ValidateEmailResponse>;

	/**
	 * Send token to service for validate email
	 *
	 * @param responses validate email responses
	 */
	abstract sendTokenToValidate(responses: any): Observable<any>;

	/**
	 * Sends the draft email when is CAISS
	 *
	 * @param responses email CAISS: numDocumento and email
	 */
	abstract sendCaissEmail(responses: EmailCaiss): Observable<any>;

	/**
	 * Gets the JSON form with the form data
	 *
	 * @param config form configuration
	 */
	abstract getFormData(config?: FormConfig): Observable<any>;

	/**
	 * Checks against the police service if the ID is valid
	 *
	 * @param docType identification document type
	 * @param docNumber identification document number
	 * @param supportNumber idnetification document support number
	 * @param firstName first name
	 * @param surname surname 1
	 * @param surname2 surname 2
	 * @param saveToken save token
	 */
	abstract dniAuth(docType: any, docNumber: any, supportNumber: any, firstName?: any, surname?: any, surname2?: any, saveToken?: any): Observable<any>;

	/**
	 * Generates CSV and sends email to user
	 *
	 * @param userEmail email we want to check if is verified or we want to send CSV
	 */
	abstract validateEmail(userEmail: string): Observable<any>;

	/**
	 * Sends pair email, CSV to verify email
	 *
	 * @param userEmail email we want to verify
	 * @param csvToken CSV token received on email
	 * @param saveToken save token
	 */
	abstract verifyEmail(userEmail: string, csvToken: any, saveToken?: boolean): Observable<any>;

	/**
	 * Saves request id, answers, version and isCAISS
	 *
	 * @param id id
	 * @param version form version
	 * @param data data
	 */
	abstract saveSolicitud(id: string | null, version: number, data: any[]): Observable<any>;
	abstract saveSolicitudV1(id: string | null, version: number, data: any[]): Observable<any>;

	abstract updatePartialSolicitud(id: string, version: number, data: any[]): Observable<any>;

	/**
	 * Recovers request from recovery request endpoint
	 *
	 * @param dni dni
	 * @param userEmail email
	 * @param token email
	 */
	abstract recoverSolicitud(dni: string, userEmail: string, token: string): Observable<any>;

	/**
	 * Revocovers CAISS request from recovery request endpoint
	 *
	 * @param dni dni
	 */
	abstract recoverSolicitudCaiss(dni: string): Observable<any>;

	/**
	 * Sends request to request endpoint
	 *
	 * @param id id
	 * @param version form version
	 * @param data data
	 */
	abstract submitSolicitud(id: string | null, version: number, data: any[]): Observable<any>;

	/**
	 * Sends CAISS request to request endpoint
	 *
	 * @param id id
	 * @param version form version
	 * @param data data
	 */
	abstract submitSolicitudCaiss(id: string | null, version: number, data: any[]): Observable<any>;

	/**
	 * Sends modifications to validation endpoint
	 *
	 * @param idExpedienteFuncional id expediente funcional
	 * @param version form version
	 * @param data data
	 */
	abstract submitModifications(idExpedienteFuncional: string, version: number, data: any[]): Observable<any>;

	/**
	 * Sends documentation to endpoint
	 *
	 * @param document document
	 */
	abstract createDocument(document: any): Observable<DocumentDto>;

	/**
	 * Sends documentation to endpoint
	 *
	 * @param data document
	 */
	abstract createRequestDocument(requestId: string, document: any): Observable<DocumentDto>;


	/**
	 * Deletes document on endpoint
	 *
	 * @param id
	 */
	abstract deleteDocument(id: string): Observable<any>;

	/**
	 * Gets document from endpoint
	 *
	 * @param body
	 */
	abstract getDocument(body: any): Observable<any>;

	/**
	 * Gets CAISS document from endpoint
	 *
	 * @param id id
	 */
	abstract getDocumentCaiss(id: any): Observable<any>;

	/**
	 * Navigates url to download document
	 *
	 * @param id id
	 */
	abstract downloadDocument(id: string): Observable<any>;

	/**
	 * Navidates url to download document with version param
	 *
	 * @param id id
	 * @param version form version
	 */
	abstract downloadDocumentIdsga(id: string, version: string): Observable<any>;

	/**
	 * Navigates to validate form step
	 *
	 * @param responses step responses
	 */
	abstract validateFormStep(responses: StepResponses): Observable<any>;

	/**
	 * Validates modifications
	 *
	 * @param responses step responses
	 */
	abstract validateModifications(responses: StepResponses): Observable<any>;

	/**
	 * Navigates to  previous claim
	 *
	 * @param form form
	 */
	abstract setPreviousClaim(form: any): Observable<any>;

	/**
	 * Sends new previous claim document to endpoint
	 *
	 * @param document document
	 */
	abstract sendNewPreviousClaimDocument(document: any): Observable<any>;

	/**
	 * Gets proceedings documents
	 *
	 * @param id id
	 */
	abstract getProceedingsDocuments(id: any): Observable<any>;

	/**
	 * Sends no repudio to endpoint
	 *
	 * @param body
	 */
	abstract sendNoRepudio(id: any): Observable<any>;

	/**
	 * Sends verificate no repudio code to endpoint
	 *
	 * @param body request body
	 */
	abstract verificateNoRepudio(id: any): Observable<any>;

	/**
	 *  Sends duplications checks to endpoint
	 *
	 * @param data request body
	 */
	abstract checkDuplication(data: any): Observable<any>;

	/**
	 * Sends expirated request to endpoint
	 *
	 * @param data request data
	 */
	abstract deleteSolicitudes(data: any): Observable<any>;

	/**
	 * Sends videoidentification qr to endpoint
	 *
	 * @param width width
	 * @param height height
	 */
	abstract getQrVideoidentification(width: number, height: number): Observable<any>;

	/**
	 * Sends videoidentification photos to web socket endpoint
	 *
	 * @param body request body
	 */
	abstract sendVideoidentificationPhotoWs(body: any): Observable<any>;

	/**
	 * Gets videoidentification photos from web sockets endpoint
	 *
	 * @param id id
	 */
	abstract getVideoidentificationPhoto(id: string): Observable<any>;

	/**
	 * Verifies captcha
	 *
	 * @param body verify captcha body
	 */
	abstract verifyCaptcha(body: VerifyCaptcha): Observable<VerifyCaptchaResponse>;

	/**
	 * Verifies CAISS registry
	 *
	 * @param numeroRegistro registry number
	 */
	abstract verifyCaissRegistry(numeroRegistro: string, provincia: string): Observable<any>;

	abstract getRequestById(id: string): Observable<any>;
}
