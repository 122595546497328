import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
	providedIn: 'root',
})
export class CaptchaService extends ReCaptchaV3Service {
	override execute = (action: string): Observable<string> => (environment?.production ? super.execute(action) : of('token'));
}

export { CaptchaService as ReCaptchaV3Service } from './captcha.service';
